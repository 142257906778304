
export function PrismicPageManipulation(data) {
    switch (data.type) {
        case "simple_page":
            return {
                content: data.data.content.html,
                slug: data.data.slug,
                title: data.data.title,
                metaTitle: data.data.meta_title,
                metaDescription: data.data.meta_description
            }
    }
}