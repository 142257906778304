import React from "react";
import "./textCard.styles.scss";


const TextCard = ({
  pageTitle,
  alignCenter = true,
  titleAttribute = "title",
  title,
  transparent,
  subTitleAttribute,
  subTitle,
  cardText,
  html
}) => {
  return (
    <article className={`card-text${alignCenter ? " center" : ""}${transparent ? " transparent" : ""}`}>
      {pageTitle ? <h1 title={titleAttribute}>{title}</h1> : <h2 title={titleAttribute}>{title}</h2> }
      {subTitle && <h3 title={subTitleAttribute}>{subTitle}</h3>}
      {cardText && <div className="card-text__text">{cardText}</div>}
      {html && <div dangerouslySetInnerHTML={{ __html: html }} className="card-text__text" />}
    </article>
  );
};

export default TextCard;