import React from "react";
import { graphql } from "gatsby";
import Layout from "../../components/layout/Layout";
import TextCard from "../../components/textCard/TextCard";
import "./simplePage.styles.scss";
import Head from "../../components/head/Head";
import { PrismicPageManipulation } from "../../utils/PrismicPageManipulation";

const SimplePage = ({ data }) => {

  const modifiedData = PrismicPageManipulation(data.prismicSimplePage);

  const homeUrl = [
    {
      url: "/",
      link: "Startseite"
    }
  ]

  return (
    <div className="page__body">
      <Head title={modifiedData.metaTitle} description={modifiedData.metaDescription} robots="noindex" />
      <Layout navbarLinks={homeUrl} sectionLink={false}>
        <main className="simple-page">
          <TextCard pageTitle={true} title={modifiedData.title} html={modifiedData.content} />
        </main>
      </Layout>
    </div >
  );
}

export default SimplePage;

export const query = graphql`
query ($slug:String) {
  prismicSimplePage(data: {slug: {eq: $slug}}) {
    data {
      content {
        html
      }
      meta_description
      meta_title
      slug
      title
    }
    uid
    type
  }
}
`

